.btnFacebook {
    border-radius: 4px;
    border:0px transparent;
    text-align: center;
    cursor: pointer;
    margin:5px;
    display: inline-block;
}
.btnFacebook {
    border-radius: 4px;
    border:0px transparent;
    background: inherit;
    text-align: center;
    margin:5px;
    display: inline-block;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

